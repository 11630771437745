/**
 * @desc 查看路由部署记录
 */

import * as defs from '../../baseClass';
import { PontCore } from '../../pontCore';

export class Params {
  /** limit */
  limit;
  /** page */
  page;
  /** application id */
  id;
}

export const init = new defs.RouteRecordResponse();

export function request(params, options = {}) {
  return PontCore.fetch(PontCore.getUrl('/record/route/{id}', params, 'GET'), {
    method: 'GET',

    ...options,
  });
}
