export class AddEcsRequest {
  /** instances */
  instances = [];
}

export class AddEcsResponse {
  /** id */
  id = [];
}

export class AddRAMAccountRequest {
  /** access_key */
  access_key = '';

  /** password */
  password = '';

  /** secret */
  secret = '';

  /** username */
  username = '';
}

export class ApplyInClusterRequest {
  /** 1 -> product 2 -> test */
  cluster_type = undefined;
}

export class ApplyInClusterResponse {
  /** record_id */
  record_id = undefined;
}

export class BasicInstanceOperateRequest {
  /** 0 -> reboot 1 -> stop 2 -> start */
  operation = undefined;
}

export class ChangeInfoRequest {
  /** avatar */
  avatar = '';

  /** nickname */
  nickname = '';

  /** real_name */
  real_name = '';
}

export class ChangeRoleRequest {
  /** role */
  role = undefined;

  /** user_id */
  user_id = undefined;
}

export class CreateTemplateRequest {
  /** application_id */
  application_id = undefined;

  /** content */
  content = '';

  /** desc */
  desc = '';

  /** is_default */
  is_default = false;

  /** name */
  name = '';
}

export class DeleteEcsRequest {
  /** ecs_id */
  ecs_id = undefined;
}

export class EcsDetailedInfo {
  /** available_zone */
  available_zone = '';

  /** cluster_id */
  cluster_id = undefined;

  /** cpus */
  cpus = undefined;

  /** desc */
  desc = '';

  /** display_name */
  display_name = '';

  /** ecs_id */
  ecs_id = '';

  /** lease_deadline */
  lease_deadline = undefined;

  /** os */
  os = '';

  /** private_ip */
  private_ip = '';

  /** public_ip */
  public_ip = '';

  /** ram */
  ram = undefined;

  /** ram_id */
  ram_id = undefined;

  /** region */
  region = '';

  /** ssh_info */
  ssh_info = new EcsSSHInfo();
}

export class EcsList {
  /** cluster_name */
  cluster_name = '';

  /** desc */
  desc = '';

  /** id */
  id = undefined;

  /** lease_deadline */
  lease_deadline = '';

  /** name */
  name = '';

  /** private_ip */
  private_ip = '';

  /** public_ip */
  public_ip = '';

  /** region */
  region = '';
}

export class EcsSSHInfo {
  /** ssh_port */
  ssh_port = undefined;

  /** ssh_pwd */
  ssh_pwd = '';

  /** ssh_username */
  ssh_username = '';
}

export class GetDetailedInfoResponse {
  /** basic_json */
  basic_json = new EcsDetailedInfo();

  /** status */
  status = '';
}

export class GetEcsListResponse {
  /** data */
  data = [];
}

export class GetInfoResponse {
  /** avatar */
  avatar = '';

  /** email */
  email = '';

  /** nickname */
  nickname = '';

  /** real_name */
  real_name = '';

  /** role */
  role = undefined;
}

export class GetInstanceVncURLResponse {
  /** url */
  url = '';
}

export class GetRamListResponse {
  /** username */
  username = [];
}

export class GetRouteInstanceInfoResponse {
  /** host */
  host = '';

  /** k8s_ingress_template_id */
  k8s_ingress_template_id = undefined;

  /** routes */
  routes = [];

  /** status */
  status = undefined;

  /** version */
  version = '';
}

export class GetRoutesListRequest {
  /** application_id */
  application_id = undefined;
}

export class GetRoutesListResponse {
  /** items */
  items = [];
}

export class GetSSHInfoResponse {
  /** ssh_info */
  ssh_info = new EcsSSHInfo();
}

export class GetTemplateResponse {
  /** content */
  content = '';

  /** description */
  description = '';

  /** is_default */
  is_default = false;

  /** version */
  version = '';
}

export class GetTemplatesListRequest {
  /** application_id */
  application_id = undefined;
}

export class GetTemplatesListResponse {
  /** list */
  list = [];
}

export class ImageUrlModel {
  /** url */
  url = '';
}

export class ImportInfoRequest {
  /** ram id */
  ram = undefined;

  /** region */
  region = '';
}

export class ImportInfoResponse {
  /** instances */
  instances = [];
}

export class Instance {
  /** cpu */
  cpu = undefined;

  /** timestamp */
  expired_time = undefined;

  /** host_name */
  host_name = '';

  /** instance_id */
  instance_id = '';

  /** memory */
  memory = undefined;

  /** os_name */
  os_name = '';

  /** private_ip_address */
  private_ip_address = '';

  /** public_ip_address */
  public_ip_address = '';

  /** region_id */
  region_id = '';

  /** zone_id */
  zone_id = '';
}

export class LogItem {
  /** content */
  content = '';

  /** level */
  level = undefined;
}

export class LogsResponse {
  /** logs */
  logs = [];
}

export class NewApplicationRequest {
  /** application_name */
  application_name = '';

  /** only contained digital or letter or _ or - */
  application_uid = '';

  /** description */
  description = '';

  /** logo_url */
  logo_url = '';

  /** members */
  members = [];
}

export class NewApplicationResponse {
  /** application_id */
  application_id = undefined;
}

export class NewInstanceRequest {
  /** application_id */
  application_id = undefined;

  /** host */
  host = '';

  /** routes */
  routes = [];

  /** template_id */
  template_id = undefined;

  /** version */
  version = '';
}

export class RAMListInfo {
  /** owner_nickname */
  owner_nickname = '';

  /** username */
  username = '';
}

export class Route {
  /** path */
  path = '';

  /** port */
  port = '';

  /** service_name */
  service_name = '';
}

export class RouteListItem {
  /** id */
  id = undefined;

  /** status */
  status = undefined;

  /** version */
  version = '';
}

export class RouteRecordResponse {
  /** deployed_cluster */
  deployed_cluster = '';

  /** deployed_env */
  deployed_env = undefined;

  /** 1 -> 部署成功 2 -> 部署中 3 -> 部署失败 4 -> 已停止 */
  deployed_status = undefined;

  /** deployed_time */
  deployed_time = '';

  /** operator */
  operator = '';

  /** record_id */
  record_id = undefined;

  /** version */
  version = '';
}

export class SignInRequest {
  /** code */
  code = '';
}

export class SignInResponse {
  /** token */
  token = '';
}

export class TemplateList {
  /** id */
  id = undefined;

  /** is_default */
  is_default = false;

  /** name */
  name = '';
}

export class UpdateApplicationRequest {
  /** expect uid -> could only be set once */
  application_name = '';

  /** description */
  description = '';

  /** logo_url */
  logo_url = '';

  /** members */
  members = [];
}

export class UpdateInstanceRequest {
  /** application_id */
  application_id = undefined;

  /** host */
  host = '';

  /** routes */
  routes = [];

  /** template_id */
  template_id = undefined;

  /** version */
  version = '';
}

export class handler_Response {
  /** code */
  code = undefined;

  /** data */
  data = undefined;

  /** message */
  message = '';
}
