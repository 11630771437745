/**
 * @desc 更新路由模版
 */

import * as defs from '../../baseClass';
import { PontCore } from '../../pontCore';

export class Params {
  /** Template id */
  id;
}

export const init = new defs.handler_Response();

export function request(params, body, options = {}) {
  return PontCore.fetch(
    PontCore.getUrl('/route/template/{id}', params, 'PUT'),
    {
      method: 'PUT',

      body,
      ...options,
    },
  );
}
