/**
 * @desc 上传文件，图片，返回url，即为上传头像
 */

import * as defs from '../../baseClass';
import { PontCore } from '../../pontCore';

export class Params {}

export const init = new defs.ImageUrlModel();

export function request(params, form, options = {}) {
  return PontCore.fetch(PontCore.getUrl('/upload/image/', params, 'POST'), {
    method: 'POST',
    body: form,

    ...options,
  });
}
