import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Cluster from './cluster'
import Server from './server'
import User from './user'
import Addserver from './addServer'
import Layout from './layout'
import Detail from './server/detail'

const App: React.FC = () => {
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (!token) {
            window.location.href = `http://pass.muxi-tech.xyz/#/login_auth?landing=${window.location.host}/landing&client_id=51f03389-2a18-4941-ba73-c85d08201d42`
        }
    }, [])
    return (
        <Router>
            <div>
                <Layout>
                    <Switch>
                        <Route path="/cluster" component={Cluster} />
                        <Route exact path="/server" component={Server} />
                        <Route path="/addServer" component={Addserver} />
                        <Route path="/user" component={User} />
                        <Route path="/server/detail/:id/" component={Detail} />
                    </Switch>
                </Layout>
            </div>
        </Router>
    )
}

export default App
