import React from 'react'
import { Layout, Menu } from 'antd'
import { NavLink } from 'react-router-dom'
import './index.less'
import { DesktopOutlined, PieChartOutlined, UserOutlined } from '@ant-design/icons'

const { Header, Content, Sider } = Layout
const { SubMenu } = Menu

const Layouts: React.FC = (props) => {
    const { children } = props
    return (
        <Layout className="lay" style={{ minHeight: '100vh' }}>
            <Sider className="sider">
                <div className="logo">MAE</div>
                <Menu theme="dark" defaultSelectedKeys={['4']} mode="inline" style={{ padding: 0 }}>
                    <SubMenu key="sub1" icon={<PieChartOutlined />} title="集群">
                        <Menu.Item>
                            <NavLink to="/cluster" className="link">
                                集群管理
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item>
                            <NavLink to="/cluster" className="link">
                                应用列表
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub2" icon={<DesktopOutlined />} title="服务器">
                        <Menu.Item>
                            <NavLink to="/server" className="link">
                                服务器列表
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item>
                            <NavLink to="/addServer" className="link">
                                新增服务器
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub3" icon={<UserOutlined />} title="用户">
                        <Menu.Item>
                            <NavLink to="/user" className="link">
                                用户列表
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }} />
                <Content style={{ margin: '40px 16px' }}>{children}</Content>
            </Layout>
        </Layout>
    )
}

export default Layouts
