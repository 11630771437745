/**
 * @desc 获取应用下的所有路由实例
 */

import * as defs from '../../baseClass';
import { PontCore } from '../../pontCore';

export class Params {
  /** limit */
  limit;
  /** page */
  page;
}

export const init = new defs.GetRoutesListResponse();

export function request(params, body, options = {}) {
  return PontCore.fetch(
    PontCore.getUrl('/route/list/instance', params, 'GET'),
    {
      method: 'GET',

      body,
      ...options,
    },
  );
}
