/**
 * @desc 移除ECS
 */

import * as defs from '../../baseClass';
import { PontCore } from '../../pontCore';

export class Params {}

export const init = new defs.handler_Response();

export function request(params, body, options = {}) {
  return PontCore.fetch(PontCore.getUrl('/ecs', params, 'DELETE'), {
    method: 'DELETE',

    body,
    ...options,
  });
}
