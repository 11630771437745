const Request = (url, options) => {
    const token = localStorage.getItem('token')
    if (options === void 0) {
        options = {}
    }
    url = `/api/v1${url}`
    
    options.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
    options.headers.Authorization = `Bearer ` + token
    if (options.body) {
        options.body = JSON.stringify(options.body)
    }

    return fetch(url, options).then(function (res) {
        return res.json()
    }).then((res) => { 
        if (res.code === 20103) {
            localStorage.clear()
            window.location.href = `http://pass.muxi-tech.xyz/#/login_auth?landing=${window.location.host}/landing&client_id=51f03389-2a18-4941-ba73-c85d08201d42`
        }
        else
        return res
     })
}

export default Request


 