/* eslint-disable camelcase */
import React from 'react'
import { Form, Input, DatePicker, Space, Select, InputNumber, Button, message } from 'antd'
import './index.less'
import { useHistory } from 'react-router-dom'

const { Option } = Select
const { TextArea } = Input

const Addserver: React.FC = () => {
    const history = useHistory()
    const onFinish = (value) => {
        API.ecs.postEcs
            .request('', {
                instances: [
                    {
                        ...value,
                        ssh_info: { ...value.ssh_info },
                        lease_deadline: value.lease_deadline.unix()
                    }
                ]
            })
            .then(() => {
                message.success('新增服务器成功')
                history.push('/server')
            })
    }
    return (
        <div className="container">
            <Space direction="horizontal" className="space">
                <Form
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 200 }}
                    layout="horizontal"
                    onFinish={onFinish}
                >
                    <Form.Item label="集群ID:" name="cluster_id" rules={[{ required: true }]}>
                        <Select defaultValue={0} style={{ width: 120 }}>
                            <Option value={0}>0</Option>
                            <Option value={1}>1</Option>
                            <Option value={2}>2</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="描述:"
                        name="desc"
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <TextArea placeholder="desc" />
                    </Form.Item>
                    <Form.Item label="cpu:" name="cpus" rules={[{ required: true }]}>
                        <InputNumber placeholder="cpus" />
                    </Form.Item>
                    <Form.Item
                        label="备注:"
                        name="display_name"
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input placeholder="display_name" />
                    </Form.Item>

                    <Form.Item
                        label="ECSID:"
                        name="ecs_id"
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input placeholder="ecs_id" />
                    </Form.Item>
                    <Form.Item label="到期时间:" name="lease_deadline" rules={[{ required: true }]}>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        label="系统版本:"
                        name="os"
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input placeholder="os" />
                    </Form.Item>
                    <Form.Item
                        label="内网IP:"
                        name="private_ip"
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input placeholder="private_ip" />
                    </Form.Item>
                    <Form.Item
                        label="公网IP:"
                        name="public_ip"
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input placeholder="public_ip" />
                    </Form.Item>
                    <Form.Item label="内存:" name="ram" rules={[{ required: true }]}>
                        <InputNumber placeholder="ram" />
                    </Form.Item>
                    <Form.Item label="子账号:" name="ram_id" rules={[{ required: true }]}>
                        <InputNumber placeholder="ram_id" />
                    </Form.Item>
                    <Form.Item
                        label="区域:"
                        name="region"
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input placeholder="region" />
                    </Form.Item>

                    <Form.Item
                        label="可用区:"
                        name="available_zone"
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input placeholder="available_zone" />
                    </Form.Item>
                    <Form.Item
                        label="SSH端口:"
                        name={['ssh_info', 'ssh_port']}
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="ssh_port" />
                    </Form.Item>
                    <Form.Item
                        label="SSH密码:"
                        name={['ssh_info', 'ssh_pwd']}
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input placeholder="ssh_pwd" />
                    </Form.Item>
                    <Form.Item
                        label="SSH用户名:"
                        name={['ssh_info', 'ssh_username']}
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input placeholder="ssh_username" />
                    </Form.Item>

                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Space>
        </div>
    )
}

export default Addserver
