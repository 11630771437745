import React, { useState, useEffect } from 'react'
import { Row, Col, Select, Divider, Table, Input, Space, message } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import './index.css'
import { useHistory } from 'react-router-dom'

const { Option } = Select
const { Search } = Input

const ItemSelect = ({ onParamsChange }) => {
    interface paramSearch {
        region: string
        name: string
        clusterId: string
    }

    const [param, setParam] = useState<paramSearch>({ region: '', name: '', clusterId: '' })

    useEffect(() => {
        onParamsChange(param.region, param.name, param.clusterId)
    }, [param])

    const handleRegionChange = (val: string) => {
        const newParam = { ...param, region: val }
        setParam(newParam)
    }

    const handleClusterIdChange = (val: string) => {
        const newParam = { ...param, clusterId: val }
        setParam(newParam)
    }

    const handleSearch = (val) => {
        const newParam = { ...param, name: val }
        setParam(newParam)
    }

    return (
        <Row align="middle" justify="space-around">
            <Col span={4}>
                <Select placeholder="Region筛选" onChange={handleRegionChange}>
                    <Option value="cn-hangzhou">中国杭州</Option>
                    <Option value="华东">华东</Option>
                    <Option value="test">test</Option>
                    <Option value="1">1</Option>
                    <Option value="">Region筛选</Option>
                </Select>
            </Col>
            <Col span={4}>
                <Select placeholder="集群筛选" onChange={handleClusterIdChange}>
                    <Option value="">集群筛选</Option>
                </Select>
            </Col>
            <Space>
                <Search allowClear placeholder="输入服务器名或id搜索" onSearch={handleSearch} />
            </Space>
            <Col span={4}>
                <Select placeholder="到期时间筛选">
                    <Option value="">到期时间筛选</Option>
                </Select>
            </Col>
        </Row>
    )
}

interface ResultListProps {
    data: defs.EcsList[]
    isLoading: boolean
}
const ResultList: React.FC<ResultListProps> = ({ data, isLoading }) => {
    const history = useHistory()
    const handleDetail = (i) => {
        history.push(`/server/detail/${i}`)
    }
    const columns = [
        {
            title: '名字',
            dataIndex: 'name',
            width: 200
        },
        {
            title: '描述',
            dataIndex: 'desc',
            width: 200
        },
        {
            title: '释放时间',
            dataIndex: 'lease_deadline',
            width: 200
        },
        {
            title: '私网IP',
            dataIndex: 'private_ip',
            width: 250
        },
        {
            title: '公网IP',
            dataIndex: 'public_ip',
            width: 250
        },
        {
            title: '操作',
            dataIndex: 'id',
            render: (id) => {
                return (
                    <div className="tooltip">
                        <FormOutlined onClick={() => handleDetail(id)} />
                        <span className="tooltiptext">查看详情</span>
                    </div>
                )
            }
        }
    ]
    const newData = []
    for (let i = 0; i < data?.length; i += 1) {
        newData.push({ key: data[i].id, ...data[i] })
    }
    return (
        <Table
            columns={columns}
            dataSource={newData}
            pagination={{ pageSize: 4 }}
            scroll={{ y: 300 }}
            loading={isLoading}
        />
    )
}

const Server: React.FC = () => {
    const [list, setList] = useState<defs.EcsList[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    function changeQueryParams(region: string, name: string, clusterId: string) {
        setIsLoading(true)
        API.ecs.getEcsList
            .request({ region, name, clusterId, page: '1', limit: '1000' })
            .then((res) => {
                setList(res.data?.data)
                setIsLoading(false)
            })
            .catch((error) => {
                throw message.error(error)
            })
    }
    return (
        <div className="server-container">
            <div>
                <ItemSelect onParamsChange={changeQueryParams} />
                <Divider />
                <ResultList data={list} isLoading={isLoading} />
            </div>
        </div>
    )
}

export default Server
