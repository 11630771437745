import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './index.css'
import { Provider } from 'react-redux'
import Landingpage from 'pages/landing'
import Request from './services/fetchMiddleware'
import { PontCore } from './services/pontCore'
import './services'
import App from './pages'
import { store } from './app/store'
import * as serviceWorker from './serviceWorker'
import 'antd/dist/antd.css'

PontCore.useFetch(Request)
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route exact path="/" component={App} />
                    <Route path="/landing" component={Landingpage} />
                </Switch>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)
serviceWorker.unregister()
