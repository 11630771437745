/**
 * @desc 终止已部署在集群上的某个路由实例
 */

import * as defs from '../../baseClass';
import { PontCore } from '../../pontCore';

export class Params {
  /** id */
  id;
}

export const init = new defs.handler_Response();

export function request(params, options = {}) {
  return PontCore.fetch(
    PontCore.getUrl('/route/cluster/{id}', params, 'DELETE'),
    {
      method: 'DELETE',

      ...options,
    },
  );
}
