/**
 * @desc 更新应用信息
 */

import * as defs from '../../baseClass';
import { PontCore } from '../../pontCore';

export class Params {
  /** Application id */
  id;
}

export const init = new defs.UpdateApplicationRequest();

export function request(params, options = {}) {
  return PontCore.fetch(PontCore.getUrl('/application/{id}', params, 'PUT'), {
    method: 'PUT',

    ...options,
  });
}
