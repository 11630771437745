/**
 * @desc ECS基本操作，重启 暂停 启动
 */

import * as defs from '../../baseClass';
import { PontCore } from '../../pontCore';

export class Params {
  /** ecs id */
  id;
}

export const init = new defs.handler_Response();

export function request(params, body, options = {}) {
  return PontCore.fetch(PontCore.getUrl('/ecs/operation/{id}', params, 'PUT'), {
    method: 'PUT',

    body,
    ...options,
  });
}
