import * as application from './application';
import * as ecs from './ecs';
import * as ram from './ram';
import * as record from './record';
import * as route from './route';
import * as upload from './upload';
import * as user from './user';

window.API = {
  application,
  ecs,
  ram,
  record,
  route,
  upload,
  user,
};
