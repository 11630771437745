import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const Landingpage: React.FC = () => {
    const history = useHistory()
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        const code = searchParams.get('accessCode')
        if (code) {
            API.user.postLogin.request('', { code }).then((response) => {
                if (!response.code) {
                    localStorage.setItem('token', response.data.token)
                    history.push('/')
                }
            })
        }
    }, [])
    return <div>登录中...</div>
}

export default Landingpage
