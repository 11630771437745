import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import moment from 'moment'

import './index.less'

interface IMatchParams {
    id?: string
}
interface DetailPageProps extends RouteComponentProps<IMatchParams> {
    data: string
}
const Detail: React.FC<DetailPageProps> = (props) => {
    const match = { props }
    const paramid = match.props.match.params.id
    interface detailData {
        ecsId: string
        publicIp: string
        privateIp: string
        sshInfo: {
            sshPort: number
            sshPwd: string
            sshUsername: string
        }
        clusterId: number
        availableZone: string
        cpus: number
        desc: string
        displayName: string
        leaseDeadline: string
        os: string
        ram: number
        ramId: number
        region: string
    }
    const [detail, setDetail] = useState<detailData>({
        ecsId: '',
        publicIp: '',
        privateIp: '',
        sshInfo: {
            sshPort: 0,
            sshPwd: '',
            sshUsername: ''
        },
        clusterId: 0,
        availableZone: '',
        cpus: 0,
        desc: '',
        displayName: '',
        leaseDeadline: '',
        os: '',
        ram: 0,
        ramId: 0,
        region: ''
    })
    interface dataList {
        key: string
        value: string | number
    }
    function handleDetail(res: detailData): Array<dataList> {
        const newdatas = []
        Object.getOwnPropertyNames(res).forEach((key) => {
            if (key === 'sshInfo')
                Object.getOwnPropertyNames(res[key]).forEach((sshKey) => {
                    newdatas.push({ key: `${sshKey}`, value: res[key][sshKey] })
                })
            else newdatas.push({ key: `${key}`, value: res[key] })
        })
        return newdatas
    }
    function getDetail(id: string) {
        API.ecs.getEcsInfoById.request({ id }).then((res) => {
            const basicJson = res.data.basic_json
            const timer = basicJson.lease_deadline
            const deadline = moment.unix(timer).format('YYYY-MM-DD')
            setDetail({
                ecsId: basicJson.ecs_id,
                publicIp: basicJson.public_ip,
                privateIp: basicJson.private_ip,
                sshInfo: {
                    sshPort: basicJson.ssh_info.ssh_port,
                    sshPwd: basicJson.ssh_info.ssh_pwd,
                    sshUsername: basicJson.ssh_info.ssh_username
                },
                clusterId: basicJson.cluster_id,
                availableZone: basicJson.available_zone,
                cpus: basicJson.cpus,
                desc: basicJson.desc,
                displayName: basicJson.display_name,
                leaseDeadline: deadline,
                os: basicJson.os,
                ram: basicJson.ram,
                ramId: basicJson.ram_id,
                region: basicJson.region
            })
        })
    }
    useEffect(() => {
        if (paramid) {
            getDetail(paramid)
        }
    }, [paramid])
    const datas = handleDetail(detail)
    return (
        <div className="detail-container">
            <div className="detail-layer">
                <h1>服务器{detail.displayName}详细数据</h1>
                {datas.map((data) => (
                    <div key={data.key}>
                        {data.key}:{data.value}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Detail
