/**
 * @desc 根据条件查询ECS
 */

import * as defs from '../../baseClass';
import { PontCore } from '../../pontCore';

export class Params {
  /** region */
  region;
  /** name or id */
  name;
  /** cluster id */
  clusterId;
  /** page */
  page;
  /** limit */
  limit;
}

export const init = new defs.GetEcsListResponse();

export function request(params, options = {}) {
  return PontCore.fetch(PontCore.getUrl('/ecs/list', params, 'GET'), {
    method: 'GET',

    ...options,
  });
}
